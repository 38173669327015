<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-05-29 15:20:16
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-29 15:20:44
 * @FilePath: /siana_admin/src/views/white/white.vue
-->
<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$router.go(-1)
  }
}
</script>

<style>
</style>
